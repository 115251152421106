<script lang="ts">
    import { getContext } from 'svelte';
    const settingsContext = getContext('settingsContext');
    const { activeSettingsTab, currentSettingTab, data } = settingsContext;

    // prop from settings.svelte
    export let type;
</script>

{#if type == 'generic'}
    <div class="ml-3 mt-3">
        <h5 class="font-bold">Settings</h5>
        <h6 class="mb-3 mt-2 text-muted">Manage your account</h6>
    </div>
{:else}
    <div class="ml-3">
        <h5 class="mt-md-3 mb-0 mt-0 font-bold">
            {activeSettingsTab.charAt(0).toUpperCase() + activeSettingsTab.slice(1)}
        </h5>
        <h6 class="mb-2 mt-2 text-muted">{currentSettingTab['heading']}</h6>
    </div>
{/if}
